@import '../../styles/customMediaQueries.css';

.root {
  composes: marketplaceModalBaseStyles from global;
  padding-top: 70px;

  @media (--viewportMedium) {
    flex-basis: 567px;
  }
}

.modalContent {
  flex-grow: 1;
}

.modalTitle {
  composes: marketplaceModalTitleStyles from global;
}

.modalMessage {
  composes: marketplaceModalParagraphStyles from global;
  margin-bottom: 20px;
}

.modalMessageTitle {
  font-weight: var(--fontWeightSemiBold);
}
